export default class ParameterRental {
    constructor() {
        this.number = 0;
        this.displayPeriod = [];
        this.calculateByFullPeriod = false;
        this.allowNegativeStock = false;
        this.proRate = false;
        this.periodAddRecurrent = false;
        this.periodSubtractRecalculate = false;
        this.fullPeriod = false;
        this.customPeriod = false;
        this.dayBeforeDelivery = 0;
        this.deliveryHour = "";
        this.startHour = "";
        this.endHour = "";
        this.devolutionHour = "";
        this.dayAfterDevolution = 0;
        this.manageByPatrimony = false;
    }
    update(data) {
        this.number = data.number;
        this.displayPeriod = data.displayPeriod;
        this.calculateByFullPeriod = data.calculateByFullPeriod;
        this.allowNegativeStock = data.allowNegativeStock;
        this.proRate = data.proRate;
        this.periodAddRecurrent = data.periodAddRecurrent;
        this.periodSubtractRecalculate = data.periodSubtractRecalculate;
        this.periodRenewedRecalculate = data.periodRenewedRecalculate;
        this.fullPeriod = data.fullPeriod;
        this.customPeriod = data.customPeriod;
        this.dayBeforeDelivery = data.dayBeforeDelivery;
        this.deliveryHour = data.deliveryHour;
        this.startHour = data.startHour;
        this.endHour = data.endHour;
        this.devolutionHour = data.devolutionHour;
        this.dayAfterDevolution = data.dayAfterDevolution;
        this.manageByPatrimony = data.manageByPatrimony;
    }
}