export default class ParameterRental {
    constructor() {
        this.manageByPatrimony = false;
        this.displayPeriod = [];
        this.calculateByFullPeriod = false;
    }
    update(data) { 
        this.displayPeriod = data.displayPeriod;
        this.calculateByFullPeriod = data.calculateByFullPeriod;
        this.manageByPatrimony = data.manageByPatrimony;
    }
}