<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <Molded>
        <b-row>
          <b-col sm="12">
            <RadioGroup title="Calcular Valor da Tabela de Preço Proporcional?" field="proRate" :formName="formName"
              :options="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]" v-model="parameterRental.proRate" />
          </b-col>
        </b-row>
      </Molded>
      <br />
      <Molded>
        <span>Recalcular Valor do Produto?</span>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="4">
            <RadioGroup title="Período Antecipado" field="periodSubtractRecalculate" :formName="formName" :options="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]" v-model="parameterRental.periodSubtractRecalculate" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="4">
            <RadioGroup title="Período Extra" field="periodAddRecurrent" :formName="formName" :options="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]" v-model="parameterRental.periodAddRecurrent" />
          </b-col>

          <b-col xs="12" sm="12" md="12" lg="12" xl="4">
            <RadioGroup title="Período Renovado" field="periodSubtractRecalculate" :formName="formName" :options="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]" v-model="parameterRental.periodRenewedRecalculate" />
          </b-col>
        </b-row>
      </Molded>
      <br />
      <Molded>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4">
            <InputNumber title="Sequêncial Locação" field="number" :formName="formName" :required="true" :maxLength="9"
              type="int" v-model="parameterRental.number" />
          </b-col>
        </b-row>
      </Molded>
      <br />
      <Molded>
        <b-row>
          <b-col sm="12">
            <RadioGroup title="Período Customizado?" field="periodoLocacaoCustomizado" :formName="formName" :options="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false },
            ]" v-model="parameterRental.customPeriod" />
          </b-col>
        </b-row>
        <div v-show="parameterRental.customPeriod">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3">
              <InputNumber title="Dia(s) Saída" field="dayBeforeDelivery" :formName="formName" :required="true"
                :maxLength="2" type="int" v-model="parameterRental.dayBeforeDelivery" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3">
              <DateTime title="Hora Saída" field="deliveryHour" format="HH:mm" type="time" placeholder
                :formName="formName" :required="false" v-model="parameterRental.deliveryHour" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3">
              <DateTime title="Hora Início" field="startHour" format="HH:mm" type="time" placeholder :formName="formName"
                :required="false" v-model="parameterRental.startHour" />
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3">
              <DateTime title="Hora Fim" field="endHour" format="HH:mm" type="time" placeholder :formName="formName"
                :required="false" v-model="parameterRental.endHour" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3">
              <DateTime title="Hora Retorno" field="devolutionHour" format="HH:mm" type="time" placeholder
                :formName="formName" :required="false" v-model="parameterRental.devolutionHour" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="3">
              <InputNumber title="Dia(s) Retorno" field="dayAfterDevolution" :formName="formName" :required="true"
                :maxLength="2" type="int" v-model="parameterRental.dayAfterDevolution" />
            </b-col></b-row>
        </div>
      </Molded>
      <br />
      <Molded>
        <b-row>
          <b-col sm="12">
            <RadioGroup title="Disponibilidade de Estoque" field="fullPeriod" :formName="formName" :options="[
              { text: 'Período Completo (Saída e Retorno)', value: true },
              { text: 'Somente Período de Locação', value: false },
            ]" v-model="parameterRental.fullPeriod" />
          </b-col>
        </b-row>
      </Molded>
    </div>
  </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import FileUpload from "@nixweb/nixloc-ui/src/component/forms/FileUpload";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import ParameterRental from "@/components/modules/adm/parameter/ParameterRental.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    InputText,
    InputNumber,
    Molded,
    DateTime,
    EditorHtml,
    FileUpload,
    Button,
    Loading,
    RadioGroup,
    CheckboxGroup,
    Alert,
  },
  name: "ParameterRental",
  data() {
    return {
      formName: "updateParameterRental",
      parameterRental: new ParameterRental(),
      urlUpdate: "/api/v1/adm/parameter-rental/update",
      urlGetById: "/api/v1/adm/parameter-rental/get-by-companyid",
      loading: true,
    };
  },
  created() {
    this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    ...mapGetters("user", ["hasModule"]),
  },
  methods: {
    ...mapActions("generic", ["putApi", "getApi"]),
    ...mapMutations("generic", ["addLoading", "removeLoading", "addEvent"]),
    ...mapMutations("validation", ["resetValidation", "updateFormDirty"]),
    ...mapMutations("user", ["updateParameterRental"]),
    getById() {
      this.addLoading(["parameterRental"]);
      let params = { url: this.urlGetById, id: this.id };
      this.getApi(params).then((response) => {
        this.parameterRental.update(response.content);
        let self = this;
        setTimeout(function () {
          self.updateFormDirty(false);
          self.loading = false;
          self.removeLoading(["parameterRental"]);
        }, 350);
      });
    },
    update() {
      this.parameterRental.displayPeriod = this.deleteMinuteAndHour(
        this.parameterRental.displayPeriod
      );

      let params = { url: this.urlUpdate, obj: this.parameterRental };
      console.log(this.parameterRental);
      this.putApi(params).then((response) => {
        if (response.success) {
          this.updateParameterRental(response.content);
          this.addEvent({ name: "renovateToken" });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    deleteMinuteAndHour(options) {
      let calculateByFullPeriod = this.parameterRental.calculateByFullPeriod;
      let optionsWithoutMinuteAndHour = [];

      if (!calculateByFullPeriod) {
        let filter = options.filter(function (item) {
          return item != "1" && item != "2";
        });
        optionsWithoutMinuteAndHour = filter;
      } else {
        optionsWithoutMinuteAndHour = options;
      }
      return optionsWithoutMinuteAndHour;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
