<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <br />
      <Molded>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputNumber title="Sequêncial Fatura" field="number" :formName="formName" :required="true" :maxLength="9"
              type="int" v-model="parameterBilling.number" />
          </b-col>
        </b-row>
      </Molded>
      <br />
    </div>
  </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";

import ParameterBilling from "@/components/modules/adm/parameter/ParameterBilling.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Loading,
    InputNumber,
    Molded,
  },
  name: "ParameterBilling",
  data() {
    return {
      formName: "updateParameterBilling",
      parameterBilling: new ParameterBilling(),
      urlUpdate: "/api/v1/adm/parameter-billing/update",
      urlGetById: "/api/v1/adm/parameter-billing/get-by-companyid",
      loading: true,
    };
  },
  created() {
    this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["putApi", "getApi"]),
    ...mapMutations("generic", ["addLoading", "removeLoading", "addEvent"]),
    ...mapMutations("validation", ["resetValidation", "updateFormDirty"]),
    ...mapMutations("user", ["updateParameterBilling"]),
    getById() {
      this.addLoading(["parameterBilling"]);
      let params = { url: this.urlGetById, id: this.id };
      this.getApi(params).then((response) => {
        this.parameterBilling.update(response.content);
        let self = this;
        setTimeout(function () {
          self.updateFormDirty(false);
          self.loading = false;
          self.removeLoading(["parameterBilling"]);
        }, 350);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.parameterBilling };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.updateParameterBilling(response.content);
          this.addEvent({ name: "renovateToken" });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
