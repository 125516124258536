var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"center":false}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('br'),_c('Molded',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('RadioGroup',{attrs:{"title":"Permitir Saída com Estoque Negativo?","field":"AllowNegativeStock","formName":_vm.formName,"options":[
              { text: 'Sim', value: true },
              { text: 'Não', value: false } ]},model:{value:(_vm.parameterStock.allowNegative),callback:function ($$v) {_vm.$set(_vm.parameterStock, "allowNegative", $$v)},expression:"parameterStock.allowNegative"}})],1)],1)],1),_c('br'),_c('Molded',[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"6"}},[_c('RadioGroup',{attrs:{"title":"Permitir Saída Menor que a Locação?","field":"AllowNegativeStock","formName":_vm.formName,"options":[
              { text: 'Sim', value: true },
              { text: 'Não', value: false } ]},model:{value:(_vm.parameterStock.allowOutputLess),callback:function ($$v) {_vm.$set(_vm.parameterStock, "allowOutputLess", $$v)},expression:"parameterStock.allowOutputLess"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"6"}},[_c('RadioGroup',{attrs:{"title":"Permitir Saída Maior que a Locação?","field":"AllowNegativeStock","formName":_vm.formName,"options":[
              { text: 'Sim', value: true },
              { text: 'Não', value: false } ]},model:{value:(_vm.parameterStock.allowOutputGreater),callback:function ($$v) {_vm.$set(_vm.parameterStock, "allowOutputGreater", $$v)},expression:"parameterStock.allowOutputGreater"}})],1)],1)],1),_c('br'),_c('Molded',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('RadioGroup',{attrs:{"title":"Permitir Devolução Menor que a Locação?","field":"AllowNegativeStock","formName":_vm.formName,"options":[
              { text: 'Sim', value: true },
              { text: 'Não', value: false } ]},model:{value:(_vm.parameterStock.allowDevolutionLess),callback:function ($$v) {_vm.$set(_vm.parameterStock, "allowDevolutionLess", $$v)},expression:"parameterStock.allowDevolutionLess"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }