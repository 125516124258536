<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <Molded>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4">
            <SelectStatic title="Fuso Horário" fieldTarget="timeZone" :data="[
                { content: 'UTC-02:00 Fernando de Noronha', id: 'UTC-02' },
                { content: 'UTC-03:00 Brasília', id: 'E. South America Standard Time' },
                { content: 'UTC-04:00 Amazônia', id: 'Central Brazilian Standard Time' },
                { content: 'UTC-05:00 Acre', id: 'SA Pacific Standard Time' },
              ]" :markFormDirty="true" v-model="parameterSystem.timeZone" />
          </b-col>
        </b-row>
      </Molded>
    </div>
  </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import ParameterSystem from "@/components/modules/adm/parameter/ParameterSystem.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Loading,
    SelectStatic,
    Molded,
  },
  name: "ParameterSystem",
  data() {
    return {
      formName: "updateParameterSystem",
      parameterSystem: new ParameterSystem(),
      urlUpdate: "/api/v1/adm/parameter-system/update",
      urlGetById: "/api/v1/adm/parameter-system/get-by-companyid",
      loading: true,
    };
  },
  created() {
    this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["putApi", "getApi"]),
    ...mapMutations("generic", ["addLoading", "removeLoading", "addEvent"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    ...mapMutations("user", ["updateParameterRental"]),
    getById() {
      let params = { url: this.urlGetById, id: this.id };
      this.getApi(params).then((response) => {
        this.parameterSystem.update(response.content);
        let self = this;
        setTimeout(function () {
          self.loading = false;
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.parameterSystem };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.updateParameterRental(response.content);
          this.addEvent({ name: "renovateToken" });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
