<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <br />
      <Molded>
        <b-row>
          <b-col sm="12">
            <RadioGroup title="Permitir Saída com Estoque Negativo?" field="AllowNegativeStock" :formName="formName"
              :options="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]" v-model="parameterStock.allowNegative" />
          </b-col>
        </b-row>
      </Molded>
      <br />
      <Molded>
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="6">
            <RadioGroup title="Permitir Saída Menor que a Locação?" field="AllowNegativeStock" :formName="formName"
              :options="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]" v-model="parameterStock.allowOutputLess" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="6">
            <RadioGroup title="Permitir Saída Maior que a Locação?" field="AllowNegativeStock" :formName="formName"
              :options="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]" v-model="parameterStock.allowOutputGreater" />
          </b-col>
        </b-row>
      </Molded>
      <br />
      <Molded>
        <b-row>
          <b-col sm="12">
            <RadioGroup title="Permitir Devolução Menor que a Locação?" field="AllowNegativeStock" :formName="formName"
              :options="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]" v-model="parameterStock.allowDevolutionLess" />
          </b-col>
        </b-row>
      </Molded>
    </div>
  </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import ParameterStock from "@/components/modules/adm/parameter/ParameterStock.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Molded,
    Loading,
    RadioGroup,
    Alert,
  },
  name: "ParameterStock",
  data() {
    return {
      formName: "updateParameterStock",
      parameterStock: new ParameterStock(),
      urlUpdate: "/api/v1/adm/parameter-stock/update",
      urlGetById: "/api/v1/adm/parameter-stock/get-by-companyid",
      loading: true,
    };
  },
  created() {
    this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    ...mapGetters("user", ["hasModule"]),
  },
  methods: {
    ...mapActions("generic", ["putApi", "getApi"]),
    ...mapMutations("generic", ["addLoading", "removeLoading", "addEvent"]),
    ...mapMutations("validation", ["resetValidation", "updateFormDirty"]),
    ...mapMutations("user", ["updateParameterStock"]),
    getById() {
      this.addLoading(["parameterStock"]);
      let params = { url: this.urlGetById, id: this.id };
      this.getApi(params).then((response) => {
        this.parameterStock.update(response.content);
        let self = this;
        setTimeout(function () {
          self.updateFormDirty(false);
          self.loading = false;
          self.removeLoading(["parameterStock"]);
        }, 350);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.parameterStock };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.updateParameterStock(response.content);
          this.addEvent({ name: "renovateToken" });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          this.update();
        }
      },
      deep: true,
    },
  },
};
</script>
