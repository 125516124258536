<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-main">
        <br />
        <div>
          <b-tabs>
            <b-tab title="Locação" @click="currentTab('rental')">
              <ParameterRental v-if="nameTab == 'rental'" />
            </b-tab>
            <b-tab title="Estoque" @click="currentTab('stock')">
              <ParameterStock v-if="nameTab == 'stock'" />
            </b-tab>
            <b-tab title="Fatura" v-if="hasModule('Billing')" @click="currentTab('billing')">
              <ParameterBilling v-if="nameTab == 'billing'" />
            </b-tab>
            <b-tab title="Sistema" @click="currentTab('system')">
              <ParameterSystem v-if="nameTab == 'system'" />
            </b-tab>
            <b-tab title="Zona de Perigo" v-if="showDangerZone" @click="currentTab('dangerZone')">
              <ParameterDangerZone v-if="nameTab == 'dangerZone'" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import ParameterDangerZone from "../../../components/modules/adm/parameter/ParameterDangerZone.vue";
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import ParameterBilling from "../../../components/modules/adm/parameter/ParameterBilling.vue";
import ParameterRental from "@/components/modules/adm/parameter/ParameterRental.vue";
import ParameterSystem from "../../../components/modules/adm/parameter/ParameterSystem.vue";
import ParameterStock from "../../../components/modules/adm/parameter/ParameterStock.vue";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ParameterView",
  components: {
    ViewTemplateWithSalveCancel,
    ParameterBilling,
    ParameterRental,
    ParameterSystem,
    ParameterStock,
    ParameterDangerZone,
  },
  data() {
    return {
      panel: {
        module: "Adm",
        title: "Parâmetro",
        formName: "updateParameterRental",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      nameTab: "rental",
      urlRenovateToken: "/api/v1/adm/auth/renovate-token",
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasModule"]),
    ...mapState("user", ["userLogged"]),
    showDangerZone() {
      let user = this.userLogged;
      if (user.user.userName == "UserTemp" || user.companyId == 3) return true;
      return false;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("user", ["updateToken"]),
    renovateToken() {
      let params = { url: this.urlRenovateToken, notNotifyToast: true };
      this.postApi(params).then((response) => {
        this.updateToken(response.content.token);
      });
    },
    currentTab(name) {
      this.nameTab = name;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "renovateToken") {
          this.renovateToken();
        }
      },
      deep: true,
    },
  },
};
</script>
