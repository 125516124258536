export default class ParameterRental {
    constructor() {
        this.allowNegative = false;
        this.allowOutputGreater = false;
        this.allowOutputLess = false;
        this.allowDevolutionLess = false;
    }
    update(data) {
        this.allowNegative = data.allowNegative;
        this.allowOutputGreater = data.allowOutputGreater;
        this.allowOutputLess = data.allowOutputLess;
        this.allowDevolutionLess = data.allowDevolutionLess;
    }
}